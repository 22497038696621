import { Config } from '../../interfaces/config.interface';
import Logo from './nippi-logo.svg';

const config: Config = {
  logo: Logo,
  title: 'Actividades de voluntariado',
  name: 'Nippi',
};

export default config;
